
html {
}

/* Space out content a bit */
body {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid #e5e5e5;
}
/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 19px;
}

.navbar-inverse .navbar-nav > .active > a {
  background-color: #222;
}

.navbar-toggle {
  margin-right: 10px;
}

.mobile-menu .nav > li > a {
  padding: 10px 0px 10px 0px;
}

.right-menu {
  left: unset !important;
}

.left-menu {
  right: unset !important;
}

/* Custom page footer */
.footer {
  position: absolute;
  bottom: 30px;
  padding-top: 10px;
  color: #ccc;
  border-top: 1px solid #888;
}

/* Customize container */
@media (min-width: 768px) {
  .container {
    max-width: 1030px;
  }
}
.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}
.jumbotron .btn {
  font-size: 21px;
  padding: 14px 24px;
}

/* Supporting marketing content */
.marketing {
  margin: 40px 0;
}
.marketing p + h4 {
  margin-top: 28px;
}

body {
  width: 100%;
  height: 100%;
  /* min-height: 1100px; */
  color: white;
  padding: 0px !important;
  font-size: 18px;
  font-weight: 300 !important;
}

.page-holder {
  min-height: 100vh;
}

.x-scrollable {
  overflow-x: scroll;
}

.scrollable {
  overflow-y: scroll;
  max-height: 100vh;
}

i.info:hover {
  cursor: pointer;
}

th {
  background-color: #000111 !important;
  opacity: 0.9;
  /* filter: grayscale(80%); */
  position: sticky !important;
  top: 0; /* Don't forget this, required for the stickiness */
}

select:disabled{
  background-color: #666 !important;
  border-color: #666 !important;
}

input:disabled{
  background-color: #aaa !important;
  border-color: #aaa !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-dark {
  background-color: #222 !important;
}

.navbar-nav a {
  color: #ccc;
}

.well {
  border: 0px !important;
}

.diver-badge {
  position: fixed;
  left: 250px;
  top: 150px;
  z-index: 100;
  /*width: 350px;
  height: 196px;*/
  width: 250px; /*25%;*/
  height: 140px; /*14%;*/
  filter: grayscale(100%);
  /*width: 100%;
  height: 100% !important;*/
  /*background: rgb(10, 11, 17) transparent;
  background: rgba(10, 11, 17, 0.85);*/
}

.bg-image {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  filter: grayscale(100%);
}

.highcharts-root {
  color: #ccc;
  border-radius: 3px;
}

.highcharts-title {
  color: #ccc !important;
}

.highcharts-background {
	fill: rgba(10, 11, 27, 0.40);
	stroke: rgba(10, 11, 17, 0.70);
  stroke-width: 1px;
}

.report-group {
  padding: 15px;
  text-align: center;
}

.col-centered{
  float: none;
  margin: 0 auto;
}

.report-item {
  border: 1px solid rgba(10, 11, 27, 0.70) !important;
  padding: 10px;
  margin: 15px;
  text-align: center;
  border-radius: 3px;
  background: rgba(10, 11, 27, 0.40);
}

div.bg-blue {
  color: #000080/* #5cb85c */
  /*background: #000026;*/
}

.navbar-text {
  color: #ccc !important;
}

div.feedback a, div.feedback p {
  color: #ccc !important;
}

div.bg-transparent {
  z-index: 1000;
  /* height: 100vh !important;
  background: rgb(10, 11, 17) !important; /* transparent */
  /* background: rgba(10, 11, 17, 0.70) !important; */
  /* min-height: 100% !important; */
}

nav.bg-white {
  border: 1px solid #888 !important;
  background-color: #888 !important;
  /*color: #333 !important;*/
}

div.bg-white {
  /*border: 1px solid #888 !important;*/
  /*background-color: #fff !important;*/
  background: rgba(78, 78, 78, 0);
  color: #ccc !important;
}

table td, table th, table tr {
  border: 0px solid #888 !important;
  /*background-color: #fff !important;*/
  background: rgba(78, 78, 78, 0);
  color: #333 !important;
}

table th {
  font-weight: 400;
  font-size: 18px;
}

.table > caption + thead > tr:first-child > th, 
.table > colgroup + thead > tr:first-child > th, 
.table > thead:first-child > tr:first-child > th, 
.table > caption + thead > tr:first-child > td, 
.table > colgroup + thead > tr:first-child > td, 
.table > thead:first-child > tr:first-child > td {
  color: #ccc !important;
}

table a, table td, table th, table tr {
  color: #ccc !important;
}

a.crud-link {
  margin-right: 10px;
}

.link {
  text-decoration: underline;
}



h1, h2 {
 color: #ccc !important; 
}

/*.btn-default {
  border: 1px solid #ffe !important;
  background-color: #ffe !important;
}*/

.btn {
  font-size: 16px;
  font-weight: 300 !important;
  border-radius: 3px !important;
}

.btn-psd, .btn-primary, .btn-success {
  color: #fff !important;
  background-color: #f46524 !important;
  border-color: #f46524 !important;
}

a.btn.btn-primary.active {
  color: #fff !important;
  background-color: #f46524 !important;
  border-color: #f46524 !important;
}

.btn-logout {
  color: #000 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

a {
  color: #333;
}

.bg-green .navbar-brand, .bg-green .navbar-nav > li > a {
  color: #fff;
}
/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-left: 0;
    padding-right: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

.signup {
  margin-top: 10%;
}

.nav > li > a.profile-img {
  padding: 10px 10px;
}

a.footer-link {
  color: #ccc;
}

.nav-button {
  background-color: #428bca !important;
  border-color: #357ebd !important;
}

.no-padding {
  padding: 0px !important;
}

.padding-right-10 {
 padding-right: 10px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.no-margin {
  margin: 0px !important;
}

.no-border {
  border: 0px !important;
}

ul.list {
  padding-inline-start: 0px;
}

ul.list li {
  display: block;
}

a.crud-link {
  padding: 0px !important;
}

a.show-dive:hover, .remove-series:hover {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

button {
  color: #333;
}

.header-light {
  font-weight: 100 !important;
}

a.add-new {
  color: white;
}

span.add-new:hover {
  cursor: pointer;
  color: white;
}

a.site-link {
  color: white;
}

a.underline {
  text-decoration: underline;
}

a.underline:hover {
  cursor: pointer;
}

i.remove-exposure, i.remove-dive:hover, i.remove-item:hover {
  cursor: pointer;
}

.dialog-modal {
  background-color: #333;
  color: #ccc;
}

.dialog-modal .btn {
  color: #ccc;
}

.dialog-modal .modal-body {
  color: #ccc;
}

.modal-body {
  font-size: 25px;
  color: #333;
}

.angular-google-map-container { 
  min-height: 500px;
}

#site-map {
  padding-top: 20px;
}

h4 a {
  font-weight: 300;
  font-size: 18px;
  text-decoration: underline;
}

h2 a {
  font-weight: 300;
  font-size: 18px;
  font-size: 20px;
}

textarea {
  width: 100%;
  color: #333;
}

.datepicker table a, .datepicker table td, .datepicker table th, .datepicker table tr,
.timepicker table a, .timepicker table td, .timepicker table th, .timepicker table tr {
  color: #333 !important;
}

.search {
  color: #333;
  padding-top: .3rem;
}

.nav-tabs li a {
  color: #ccc !important;
}

.nav-tabs li.active a {
  color: #333 !important;
  background-color: #fff !important;
}

.notification {
  color: #d9534f;
}

.success {
  color: #5cb85c;
}

.warning {
  color: #f0ad4e;
}

.primary {
  color: #0275d8;
}

label, label input, label.checkbox-inline {
  font-weight: 300;
}

input, select {
  color: #333;
  border-radius: 3px !important;
}

.checkbox-inline {
  margin-right: 0px;
  margin-left: 10px;

}

form div.row {
  margin-left: 15px;
}

hr.light-hr {
  color: #ccc !important;
  border-top: 1px solid !important;
}

.alert-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.alert-warning {
  color: white !important;
  background-color: orange !important;
  border-color: orange !important;
}

.alert-success {
  color: white !important;
  background-color: #53a653 !important;
  border-color: #53a653 !important;
}
/*.btn.btn-primary {*/
  /*background-color: #000050;*/
/*}*/
